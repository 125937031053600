<template>
  <select
    v-model="model"
    class="zform-select"
    :class="{ 'default-selected': model === '' }"
  >
    <option
      v-if="hasDefault"
      value=""
      disabled
      selected
    >
      <span v-if="defaultLabel !== ''">{{ defaultLabel }}</span>
      <span else>{{ t('defaultLabel') }}</span>
    </option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :selected="option.value === model && !hasDefault"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script setup lang="ts">
const { t } = useI18n()

withDefaults(defineProps<{
  options: { value: string, label: string }[]
  hasDefault?: boolean
  defaultLabel?: string
}>(), {
  hasDefault: true,
  defaultLabel: '',
})

const model = defineModel<string>()
</script>

<i18n lang="json">
{
  "en": {
    "defaultLabel": "Select"
  },
  "fr": {
    "defaultLabel": "Sélectionner"
  }
}
</i18n>
